import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="introduction">
        <h1>ellie jo llc</h1>
        <p>Product Development and Design Agency in the San Francisco Bay Area</p>
      </div>
    </div>
  );
}

export default App;
